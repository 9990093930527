import "particles.js"
import "./src/styles/reset.css"

import "prism-themes/themes/prism-material-dark.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./src/styles/vendor/icofont/icofont.min.css"
import "./src/styles/vendor/boxicons/css/boxicons.min.css"
import "aos/dist/aos.css"
import "./src/styles/global.css"
import "./src/styles/grid.scss"

import AOS from "aos"
AOS.init({
  duration: 1000,
  easing: "ease-in-out-back",
})
